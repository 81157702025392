.br_events_premium_badge {
  width: 20px;
  background-color: #a54b1a;
  color: white;
  position: absolute;
}

.br_events_premium_sliderImage {
  flex: 1;
  object-fit: cover;
  height: 100px;
  border-radius: 4px;
  width: -webkit-fill-available;
}

.br_events_premium_sliderRow {
  margin-top: -31px;
}

.br_events_premium_sliderCol {
  margin-right: 0.3rem;
}

.br_events_premium_sliderText {
  overflow: hidden;
  height: 46px;
  margin: 0px;
  padding: 0px 3px;
}

.br_events_premium_container {
  width: 200px;
  display: flex;
}

.br_events_premium_slide {
  overflow-x: auto;
  overflow-y: hidden;
}